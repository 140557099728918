import { createApp } from "vue";
import msgBox from "../components/tips/index.vue";

export function showMsg(msg,handleClick){

    const app=createApp(msgBox,{
        msg,
        onclick:handleClick,

    })
    const div=document.createElement("div");
    document.body.appendChild(div);
    app.mount(div);
    

    const close=()=>{
        app.unmount();
        document.body.removeChild(div);
    }
    
    return {close}

}
