import { showSuccessToast } from 'vant'
import request from './index'
import { userData,last_voice_url } from "@/utils/utils.js"



function setUserToken(token, uid, tokenType, refreshToken) {
    token && localStorage.setItem('AccessToken', token)
    tokenType && localStorage.setItem('tokenType', tokenType)
    uid && localStorage.setItem('uid', uid)
    refreshToken && localStorage.setItem('refreshToken', refreshToken)
}



 

// 获取用户信息
export function getUserData() {
    return new Promise((resolve, reject) => {
        request({ url: '/user/data' }).then(
            res => {
                // res.data.mall.quota.count=0;
                // res.data.last_voice_url= "http://14.103.95.118:8003/resources/audios/2-1001-8-bcb4889c48901322eea445224006080c.mp3"
                
                Object.assign(userData, res.data);
                if(userData.last_voice_url){
                    last_voice_url.value=userData.last_voice_url
                }
                // userData.mall.quota.expired="1724135008.036";
                resolve()
            }
        ).catch(e => { reject(e) })
    })
}
 

// 获取验证码
export function sendEmail(email) {
    return new Promise((resolve, reject) => {
        request({ url: '/account/auth_code', data: { email } }).then(res => {
            resolve(res)
        }).catch((e)=>{
            reject(e)
        })
    })

}

// 登录
//   - email: 邮箱
//   - auth_code: 验证码
export function bindUserEmail(pram) {
    return new Promise((resolve, reject) => {
        request({ url: '/account/login', data: {...pram} }).then(res => {
            setUserToken(res.data.access_token, res.data.uid, res.data.token_type, res.data.refresh_token)
            resolve()
        }).catch((e)=>{
            reject(e)
        })
    })
}

let promise;
// 刷新token
export async function refreshToken() {
    console.log('刷新token');
    if (promise) return promise
    promise = new Promise(async (resolve, reject) => {
        let access_token = localStorage.getItem('AccessToken')
        let res = await request({ url: '/account/refresh', data: { access_token }, hideLoading: true, isRefreshToken: true })
        if (res.status == 200) {
            setUserToken(res.data.access_token, res.data.uid, res.data.token_type, res.data.refresh_token)

            resolve(true)
        }
        else return resolve(false)
    })

    promise.finally(() => {
        promise = null
    })
    return promise
} 