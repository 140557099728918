import axios from 'axios'
import CryptoJS from 'crypto-js';
import { showDialog, showFailToast, showLoadingToast, Toast,showToast, closeToast, allowMultipleToast } from 'vant';
import { getFingerprint } from "@/utils/utils"
import { refreshToken } from "@/request/user"
import {showMsg} from "@/utils/showMsg.js";

let url = location.origin+"/api";

if(process.env.NODE_ENV=='development'||location.href.indexOf("14.103.95.118")>-1){
    url='http://14.103.95.118:8002/api'
}



allowMultipleToast();

const base64Encrypt = function (data) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
};

const md5 = function (data) {
    return CryptoJS.MD5(CryptoJS.enc.Utf8.parse(data)).toString();
};

const SECRET_VALUE = "gT^C\\|Io1BOQgLck";

export default function request(config) {
    var loadingToast = null
    if (!config.hideLoading) {
        loadingToast = showLoadingToast({
            forbidClick: true,
            duration: 0,
            "class-name": 'loading-toast'
        })
    }

    let createConfig={
        baseURL: url,
        timeout: 60000,
        method: config.method || 'POST', 
    }

    if(config.responseType&&config.responseType=="blob"){
        createConfig.responseType="blob";
    }else{
        createConfig.headers={
            'Content-Type': 'application/json',
        }
    }

    const instance = axios.create(createConfig)


    // instance.interceptors.request.use(async config => {
    //     if (config.data && typeof config.data === 'object') {
    //         const bodyString = JSON.stringify(config.data).split("").sort().join("");
    //         md5(`${base64Encrypt(bodyString)}#${SECRET_VALUE}`).toUpperCase()
    //     }
    // });

    instance.interceptors.request.use(
        async config => {
            let device = localStorage.getItem('visitorId') || await getFingerprint()
            config.data = Object.assign(config.data || {}, { device:device,role_id:"1001"})

            let token = localStorage.getItem('AccessToken')
            if (config.isRefreshToken) {
                token = localStorage.getItem('refreshToken')
            }
            token && (config.headers.Authorization = `Bearer ${token}`)

            // 签名
            if (config.data && typeof config.data === 'object') {
                const bodyString = JSON.stringify(config.data).replace(/\s+/g, '').split("").sort().join("");
                config.headers.signature = md5(`${base64Encrypt(bodyString)}#${SECRET_VALUE}`).toUpperCase()
            }
            return config
        },
        error => {
            Promise.reject(error)
        }
    )
    instance.interceptors.response.use(

        async res => {
            if (!config.hideLoading) {
                loadingToast.close()
            }
            return res
        },
        async error => {
            if (!config.hideLoading) {
                loadingToast.close()

            }
            if (!error.response) {
                const _showMsg=showMsg('[ERROR 4001] Network timeout.',()=>{
                    _showMsg.close();
                })
                throw new Error(`网络超时`);
            }
            // 处理响应数据
            if (error.response.status === 500) {
                //对于其他的500错误，均包装为网络请求错误，并附带上相应的错误信息进行抛出
                if (error.response.data.detail&&error.response.data.detail.code == 10001 && !config.isRefreshToken) {
                    let refreshComplate = await refreshToken()
                    // 重新获取token 
                    let token = localStorage.getItem('AccessToken')
                    error.config.headers.Authorization = `Bearer ${token}`
                    if (refreshComplate) {
                        // error.config.data = { a: 2 }
                        try {
                            error.config.data = JSON.parse(error.config.data)
                        } catch (error) {

                        }
                        return instance.request(error.config)
                    } else {
                        console.log('去登录1')
                        // 删除缓存
                        localStorage.removeItem('AccessToken')
                        localStorage.removeItem('uid')
                        localStorage.removeItem('tokenType')
                        localStorage.removeItem('visitorId')
                        localStorage.removeItem('refreshToken')
                        // 跳转到登录页
                        location.reload()
                    }

                }
                else if (error.response.data.detail&&(error.response.data.detail.code == 10004||error.response.data.detail.code == 10013)) {
                    console.log('10004||10013')
                    // 删除缓存
                    localStorage.removeItem('AccessToken')
                    localStorage.removeItem('uid')
                    localStorage.removeItem('tokenType')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('visitorId')
                    // 跳转到登录页
                    location.reload()
                }

                else {
                    const _showMsg=showMsg(error.response.data.detail?error.response.data.detail.message:'[ERROR 4002] Server error.',()=>{
                        _showMsg.close();
                    })
                    if(error.response.data.detail){
                        const _msg=error.response.data.detail.message;
                        if(_msg=='can not find user.'||_msg=='access token has changed.'){
                            console.log('can not find user.')
                            // 删除缓存
                            localStorage.removeItem('AccessToken')
                            localStorage.removeItem('uid')
                            localStorage.removeItem('tokenType')
                            localStorage.removeItem('refreshToken')
                            localStorage.removeItem('visitorId')
                            // 跳转到登录页
                            location.reload()
                        }
                        // token过期
                        else if(_msg=='access token decode error.'&&!config.isRefreshToken){
                            let refreshComplate = await refreshToken()
                            // 重新获取token 
                            let token = localStorage.getItem('AccessToken')
                            error.config.headers.Authorization = `Bearer ${token}`
                            if (refreshComplate) {
                                // error.config.data = { a: 2 }
                                try {
                                    error.config.data = JSON.parse(error.config.data)
                                } catch (error) {
        
                                }
                                return instance.request(error.config)
                            } else {
                                console.log('重新获取token 刷新页面')
                                // 删除缓存
                                localStorage.removeItem('AccessToken')
                                localStorage.removeItem('uid')
                                localStorage.removeItem('tokenType')
                                localStorage.removeItem('visitorId')
                                localStorage.removeItem('refreshToken')
                                // 跳转到登录页
                                location.reload()
                            }
                        }
                        
                         // 无效电子邮箱
                         else if(_msg=="invalid email."){

                            return Promise.reject(_msg)

                        }
                        // 验证码错误
                        else if(_msg=="auth data is not existed."){

                            return Promise.reject(_msg)

                        }
                        // 刷新令牌错误
                        else if(_msg=="refresh token decode error."){

                            console.log('刷新令牌错误,重登')
                            // 删除缓存
                            localStorage.removeItem('AccessToken')
                            localStorage.removeItem('uid')
                            localStorage.removeItem('tokenType')
                            localStorage.removeItem('visitorId')
                            localStorage.removeItem('refreshToken')
                            // 跳转到登录页
                            location.reload()

                        }
                    }

                    // if (!config.isRefreshToken) {
                    //     throw new Error(`网络请求错误`);
                    // }  
                    // else {
                    //     return error
                    // } 
                    // return error
                    throw new Error(`网络请求错误`);

                }
            }else if(error.response.status === 401){
                console.log('去登录2')
                // 删除缓存
                localStorage.removeItem('AccessToken')
                localStorage.removeItem('uid')
                localStorage.removeItem('tokenType')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('visitorId')
                // 跳转到登录页
                location.reload()
            }


            const _showMsg=showMsg(error.response.data.detail?error.response.data.detail.message:'[ERROR 4002] Server error.',()=>{
                _showMsg.close();
            })

            if (config.isRefreshToken) {
                return error.response
            } else {
                return Promise.reject(error)
            }

        }
    )
    return instance(config)
}