import { ref, reactive } from 'vue'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import i18n from "@/lang/index";
export const userData = reactive({})  //用户信息
export const languageCur = ref('en')  //当前语言

export const last_voice_urlDft = ref(new URL('../assets/media/voice.mp3', import.meta.url).href)  //

export const last_voice_url = ref(new URL('../assets/media/voice.mp3', import.meta.url).href)  

export const bgmState = ref(false)  




// 生成一下浏览器指纹
export function getFingerprint() {
    return new Promise((resolve, reject) => {
        FingerprintJS.load().then(fp => {
            fp.get().then(result => {
                const visitorId = result.visitorId;
                localStorage.setItem("visitorId", visitorId);
                resolve(visitorId)
            });
        });
    })

}

//时间戳转年月日 
export function formatSeconds(timestamp) {
    const date = new Date(timestamp * 1000); // 将时间戳转换成毫秒
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    // return `${year}-${month}-${day}`;
    return {year,month,day}
}



export const getLanguageText = (inst,name) => {
    let $this = inst?.appContext.config.globalProperties;
    return i18n.global.messages.value[$this.$i18n.locale][name]
}



export function copyEvent(text, successFn, errFn) {
    try {
        var input = document.createElement("input");
        input.value = text;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length);
        document.execCommand("Copy");
        document.body.removeChild(input);
        successFn && successFn();
    } catch (e) {
        errFn && errFn();
    }
}

export function calculateExpirationDate(remainingDays) {  
    let  startDate=new Date();
    // 将startDate字符串转换为Date对象  
    const start = new Date(`${startDate.getFullYear()}/${startDate.getMonth()+1}/${startDate.getDate()}`);  
    // 计算到期日期  
    const expirationDate = new Date(start);  
    expirationDate.setDate(start.getDate() + remainingDays);  
    // 返回到期日期的字符串形式，格式为"YYYY-MM-DD"  
    const year = expirationDate.getFullYear();  
    const month = (expirationDate.getMonth() + 1).toString().padStart(2, '0');  
    const day = expirationDate.getDate().toString().padStart(2, '0');  
    // return `${year}-${month}-${day}`;  
    return {year,month,day}
}  