//zh 中文语言包
export default {
    rolName:"Zhou Shen",
    sendInputPlaceholder:"whatever you want",
    notLoneSendInputPlaceholder:"Login to unlock",
    historyDataText:"History",
    login:{
        title1:"Sign In",
        title2:"Account",
        emailInputPlaceholder:"Email",
        emailInputVy:"Error: Invalid Email address!",
        codeInputPlaceholder:"Verification Code",
        codeInputVy:"Verification code check failed!",
    },
    user:{
        FreePlanText:"Free Plan",
        NoSubscription:"No active subscription",
        subscribeBtnText:"Subscribe",
        renewText:"Renew",
        userConBox2:{

            text1:" Days Plan",
            text2:"Expired on",

        },
        userConBox3:{
            text1:"Refer your friends",
            text2:"and earn up to 100",
        },
        userConBox4:{
            text1:"Both of you and your friend will get",
            text2:"bonus",
        }
    },
    inviteBtnText:"Invite Friends",
   

    pay:{

        label:{
            text1:"Store",
            text2:"Plans",
        },
        paymentStoreList_infinite:{
            text1:"24 Hours",
            text2:"Unlimited",
        },
        paymentPlans:{
            text1:"Get 10",
            text2:"bonus everyday!",
            text3:"Extra emotion controlling",
        },
        subscribe:"Subscribe",
        activityDate:"Special offer ends",
    },
    buySuccessToast:"购买成功",
    notCountTips:"Out of energy!<div style='height:3px'></div> Go to the Store to get more ⭐️energy.",
    codeSendSuccessToast:"Email has been sent successfully.<div style='height:3px'></div> Go and check your verification code.",
    invitecodeTips:"Invitation Link has been copied to clipboard.<div style='height:3px'></div> Send it to your friends and invite them to join!",
    notInvitecodeTips:"[ERROR 4101] Invalid invitation link.",
    notHistoryDataTips:"暂无历史消息"
} 