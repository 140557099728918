<template>
  <router-view></router-view>
</template>
<script setup>
import { ref, reactive, nextTick, computed, onMounted, watch } from "vue";
import { getUserData } from "@/request/user.js";
import { apiGetStory } from "@/request/story.js";
import { userData,bgmState } from "@/utils/utils.js"; 


window.snd = document.createElement("audio");
snd.preload="auto"
snd.src = new URL("./assets/media/voice.mp3", import.meta.url).href;





onMounted(() => {
  
  if (localStorage.uid) {
    getUserData();
  } 

});
</script>
<style></style>
