import { createApp } from 'vue'
import './style.css'
import 'vant/lib/index.css';
import "swiper/css";
import App from './App.vue'
import VConsole from 'vconsole'
// 如果是开发环境
if (location.href.indexOf("debug")>-1) {
 new VConsole();
}
import router from './router/index.js'

import i18n from '@/lang/index'
import {languageCur,getFingerprint } from "@/utils/utils"
// import './mock/index.js'
const app = createApp(App)
// 应用启动时获取一下指纹
getFingerprint()
// 获取当前语言
languageCur.value = localStorage.getItem('lang')||'en'
app.use(router)
app.use(i18n)

app.mount('#app')

