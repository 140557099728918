<template>
  <div class="publicTipsWrap">
    <div class="publicTipsContent">
      <div class="content" v-html="msg"></div>
      <div class="confirmBtn" >OK</div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue"; 
defineProps({
  msg: {
    type:String,
  },
});

 

onMounted(() => {

});



</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
