import { createRouter, createWebHistory } from 'vue-router';
import { userData } from '@/utils/utils';

// 路由列表
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/index.vue')
    }, 

]


// 导出路由
const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router;

// router.beforeEach((to, from, next) => {
//     if (Object.keys(userData) == 0 && to.path != '/') {
//         next('/')
//     } else next()
// })
export function toPage(link) {


    if (link == '-1') {
        router.replace('/')
        // pageCount.value = 1
    }
    else if (link.indexOf("http") > -1) {
        location.href = link;
    }
    else {
        router.replace(link)
    }
}